@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700|Noto+Sans:300,400,500,600,700,800|PT+Mono:300,400,500,600,700');

.navbar-nav {
  padding: 0;
}

.navbar .nav-link {
  padding: 0.5rem 0;
}

.theme-rtl .navbar .nav-link svg {
  margin: 0 0px 0 8px;
}

.theme-ltr .navbar .nav-link svg {
  margin: 0 8px 0 0px;
}

.theme-rtl .auth-form h1,
.theme-rtl .auth-form h2,
.theme-rtl .auth-form h3,
.theme-rtl .auth-form h4,
.theme-rtl .auth-form h5,
.theme-rtl .auth-form h6,
.theme-rtl .auth-form p,
.theme-rtl .auth-form label,
.theme-rtl .auth-form input,
.theme-rtl .auth-form form {
  text-align: right;
}

.text-error {
  color: #fd5c70;
  font-size: 10px;
  font-weight: 500;
  margin: 0 5px;
}

iframe {
  display: none !important;
}

.navbar.border-radius-sm {
  border-radius: 10px !important;
}


.navbar-brand {
  margin: 0 10px;
}


.icon-shape {
  width: 35px !important;
  height: 35px !important;
}


.user-account-svg {
  width: 17px !important;
  height: 17px !important;
}


.docs-svg {
  width: 23px !important;
  height: 23px !important;
}



@media(min-width: 1199.98px) {

  .navbar-expand-lg {
    margin-left: 15.625rem !important;
    margin-right: 0 !important;
  }
}

@media(min-width: 768px) {

  /* width */
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px transparent;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #d4d9df;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb:hover {
    background: #aaa;
  }



}

#sidenav-collapse-main {
  overflow-x: hidden;
}

@media(max-width: 768px) {

  .aside-toggle i {
    font-size: 21px;
    font-weight: 600;
    color: #a2acba;
  }

  .aside-toggle {
    position: relative;
    margin: 0 15px;
    top: 3px;
    color: #a2acba;
	margin: 0 5px;
  }

  .aside-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    background: #00000040;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    z-index: 2000;
    display: none;
  }

  .dashboard-fluid {
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 25px !important;
    padding-right: 25px !important;
  }

  aside {}

  .navbar-vertical.navbar-expand-xs.fixed-end {
    display: none;
  }

}

.block {
  display: block;
}

.active-aside {
  transform: none !important;
  display: block !important;
}

@media(min-width: 1199.98px) {
  .dashboard-fluid {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
    /* padding-right: 3rem !important;
		padding-left: 3rem !important; */
  }

  .aside-toggle {
    display: none;
  }

  .theme-ltr.in-dashboard .main-navbar {
    left: 206px !important;
    width: calc(100% - 163px);
    margin: 0 !important;
    top: 15px !important;
  }

  .theme-rtl.in-dashboard .main-navbar {
    right: 206px !important;
    width: calc(100% - 163px);
    margin: 0 !important;
    top: 15px !important;
  }

  .rtl.in-dashboard .main-content {
    width: calc(100% - 250px);
    right: 250px;
  }

  .ltr.in-dashboard .main-content {
    width: calc(100% - 250px);
    left: 250px;
  }


}

.flex {
  display: flex;
}

.main-content {
  padding-top: 55px;
}

.rtl .docs-info {
  text-align: right;
}

.rtl .docs-info i {
  margin: 0 0.25rem !important
}

.rtl .text-start {
  text-align: right !important;
}

.rtl .border-start {
  border-left: 0 !important;
  border-right: 1px solid #dde0e5 !important;
}

.rtl .navbar-vertical .navbar-nav>.nav-item.border-start {
  margin-left: 0 !important;
  margin-right: 1.6rem !important;
}

.form-control {
  padding: 0.6875rem 0.75rem !important;
}

.theme-rtl .form-control.is-valid,
.theme-rtl .form-control.is-invalid {
  background-position: left 0.75rem center !important;
}

.rtl .me-2 {
  margin-right: 0 !important;
  margin-left: 0.5rem !important;
}

.w-full {
  width: 100%;
}

.rtl .ag-shop-card-footer_link {
  left: 25px;
  right: auto !important;
}

.rtl .ag-shop-card-footer_link img {
  transform: rotate(180deg);
}

.ag-format-container {
  width: 1142px;
  margin: 0 auto;
}

.ag-container-shops {
  padding: 60px 0;

  overflow: hidden;
}
/* 
.ag-card-bg {
  height: 100%;
  width: 200%;
  background-position: 50%;
  background-size: cover;

  position: absolute;
  top: 0;
  left: -50%;

  -webkit-transition: height .6s;
  -moz-transition: height .6s;
  -o-transition: height .6s;
  transition: height .6s;
} */

.ag-card-bg {
    height: 100%;
    width: 100%;
    background-position: center;
    background-size: 90% 90%;
    background-size: contain;
    background-size: cover;
    position: absolute;
    background-repeat: no-repeat;
    top: 0;
    left: 0;
    transition: height .6s;
}

.ag-shop-card_box-wrap {
  padding: 15px;
}

.ag-shop-card_box {
  background-color: #FFF;

  overflow: hidden;

  -webkit-box-shadow: 0 10px 20px 0 rgba(0, 0, 35, .25);
  -moz-box-shadow: 0 10px 20px 0 rgba(0, 0, 35, .25);
  -o-box-shadow: 0 10px 20px 0 rgba(0, 0, 35, .25);
  box-shadow: 0 10px 20px 0 rgba(0, 0, 35, .25);

  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;

  -webkit-transition: .4s;
  -moz-transition: .4s;
  -o-transition: .4s;
  transition: .4s;
}

.ag-shop-card_body {
  display: block;
    height: 100vw;
    max-height: 340px;
    max-height: 150px;
    /* width: 300px; */
    background-position: 50%;
    background-size: cover;
    overflow: hidden;
    transition: .4s;
    position: relative;
    margin: 15px 15px 5px;
    border: 1px solid #1b1b1b10;
    border-radius: 10px;
}

.ag-shop-card-body_link {
  height: 100px;
  width: 100px;
  margin: 0 auto;

  overflow: hidden;

  z-index: 9;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;

  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;

  -webkit-box-shadow: 0 10px 20px 0 rgba(0, 0, 0, .35);
  -moz-box-shadow: 0 10px 20px 0 rgba(0, 0, 0, .35);
  -o-box-shadow: 0 10px 20px 0 rgba(0, 0, 0, .35);
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, .35);

  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);

  -webkit-transition: top .4s;
  -moz-transition: top .4s;
  -o-transition: top .4s;
  transition: top .4s;
}

.ag-shop-card-body_img {
  max-width: 100%;
}

.ag-shop-card_footer {
  padding: 15px 15px;
  border: 0;
  background-color: #FFF;

  position: relative;
}

.ag-shop-card-footer_title {
  display: block;
  line-height: 1.3;

  font-weight: 700;
  font-size: 14px;
  color: #0f172a;
  min-height: 35px;
}

.ag-shop-card-footer_products {
  display: block;
  line-height: 1.3;
  font-size: 12px;
  color: #1b1b1b90;
  font-weight: 400;
  margin: 5px 0;
}

.ag-shop-card-footer_link {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  width: 35px;
  background-color: #1e293b;

  position: absolute;
  right: 25px;
  top: 15px;

  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

.ag-shop-card-footer_arrow {
  max-width: 16px;
}

@media only screen and (max-width: 767px) {
  .ag-format-container {
    width: 96%;
  }

}

@media only screen and (max-width: 639px) {}

@media only screen and (max-width: 479px) {}

@media (min-width: 768px) and (max-width: 979px) {
  .ag-format-container {
    width: 750px;
  }

}

@media (min-width: 980px) and (max-width: 1161px) {
  .ag-format-container {
    width: 960px;
  }

}

.ag-shop-card-footer_link i,
.ag-shop-card-footer_link:hover i {
	color: #fff;
    font-size: 17px;
}

.padding-10 {
	padding: 10px;
}

.swiper-cell .swiper-slide {
  width: 100%;
}


.swiper-slide .js-carousel-cell {
  width: 100%;
}





@media(min-width: 768px) {
  .swiper-cell .swiper-slide:nth-child(2n + 1) {
    width: 33.333333%;
  }

  .swiper-cell .swiper-slide:nth-child(2n) {
    width: 50%;
  }
 
	.home-swipers {
		/* max-height: 450px; */
	}

	.swiper-slide .ag-shop-card_body {
		max-height: 240px;
	}

	
}

.rtl .ms-auto {
  margin-left: 0 !important;
  margin-right: auto !important;
}

.rtl .me-1 {
  margin-left: 0.25rem !important;
  margin-right: auto !important;
}

@media (max-width: 768px) {
	.all-products .p-one:nth-child(2n) {
		padding-left: 5px;
		padding-right: 10px;
	}
	.all-products .p-one:nth-child(2n+1) {
		padding-right: 5px;
		padding-left: 10px;
	}
}
	
@media (max-width: 1199.98px) {
	
  .theme-rtl .g-sidenav-show:not(.rtl) .sidenav {
    transform: none !important;
  }
}

.form-crul {
	padding: 20px 20px 0;
}

.p-0 {
	padding: 0;
}

.w-90 {
	width: 90%;
}

.theme-rtl .form-lang-title {
	float: left;
}

.theme-ltr .form-lang-title {
	float: right;
}

.form-lang-title {
	background: #0d5c46 ;
    color: #fff;
    padding: 3px 7px;
    font-size: 10px;
    font-weight: bold;
    border-radius: 100px;
    display: inline;
    /* margin: 0 7px; */
	position: relative;
    top: 3px;
}

.form-crul label {
	font-size: 15px;
    text-transform: capitalize;
	width: 100%;
}

.form-crul .form-check {
	border-bottom: 1px solid #1b1b1b30;
    padding-bottom: 10px;
}

.form-control {
	appearance: auto;
}

.hidden {
	display: none;
}

.p-0 {
	padding: 0;
}

.mb-15px {
	margin-bottom: 15px;
}

.mt-15px {
	margin-top: 15px;
}

.rtl .ms-1 {
	margin-left: auto !important;
	margin-right: 0.25rem !important;
}

.sidenav-header {
	height: auto !important;
}

.sidenav-header img {
    width: 100%;
    /* filter: invert(1); */
    max-height: max-content !important;
}

.navbar-vertical.navbar-expand-xs .navbar-collapse {
	height: auto !important;
}

.card-text {
	font-size: 14px;
    line-height: 30px;
}

@media(min-width: 768px){
	.dashboard-fluid {
		max-width: 1200px;
	}
}

.auth-form .card-plain {
	background: #ffffff90 !important;
    backdrop-filter: blur(16px);
    padding: 20px 25px 10px;
    border-radius: 15px;
	
	background: #ffffff !important;
    backdrop-filter: blur(0px);
    padding: 20px 25px 10px;
    border-radius: 15px;
}

.auth-pages main {
	position: relative;
    background-size: 100% 100%;
    background-repeat: no-repeat;
	/* background: linear-gradient(109.6deg, rgb(0, 0, 0) 11.2%, rgb(11, 132, 145) 91.1%) !important; */
}

/*
.auth-pages main:before {
	content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: #00000050;
    backdrop-filter: blur(4px);
}
*/

.avatar-sm {
    width: 69px !important;
    height: 69px !important;
    font-size: 0.875rem;
    border: 1px solid #1b1b1b20;
    border-radius: 10px !important;
	object-fit: contain;
}

.prices-box .col-12, 
.prices-box .col-11, 
.prices-box .col-10, 
.prices-box .col-9, 
.prices-box .col-8, 
.prices-box .col-7, 
.prices-box .col-6, 
.prices-box .col-5, 
.prices-box .col-4, 
.prices-box .col-3, 
.prices-box .col-2, 
.prices-box .col-1
{
	padding: 0
}

.prices-box {
	border-bottom: 1px solid #1b1b1b20;
    padding: 10px 0px 10px;
    align-items: center;
    margin: 5px 10px !important;
}

.prices-box p {
	    margin: 0;
    font-size: 11px;
}

.prices-box .btn {
	margin: 0;
    font-size: 11px;
    padding: 5px 10px;
    border-radius: 10px;
	width: 100%;
	
	height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.prices-box a small {
    font-size: 12px;
    font-weight: 100;
    margin: 0 5px;
}

.rtl .text-end {
    text-align: left !important;
}

.swiper-image {
    border: 1px solid #1b1b1b20;
		width: calc(100% - 10px);
	/* padding: 10px; */
}

.margin-10 {
	margin: 0 15px
}

@media(min-width: 768px){
	aside {
/* 		border-radius: 15px;
		margin: 15px; */
	}
}

.sidenav-footer {
	margin-bottom: 30px;
}

.card-title {
	margin-top: 20px;
}

.font-13 {
	font-size: 13px;
}

.width-80px {
	width: 80px;
}

.theme-rtl .menu-with-icon .nav-link {
	padding-right: 4px !important;
}

.theme-ltr .menu-with-icon .nav-link {
	padding-left: 4px !important;
}

.menu-with-icon i {
	font-size: 13px !important;
}

.Toastify__toast-body *{
	text-align: right;
}

.Toastify__toast-body > div:last-child {
	padding-right: 10px;
}

.auth-pages nav {
	display: none;
}

.auth-pages main {
	padding-top: 0;
}

.auth-pages .card-plain {
	margin: 25px 0;
	padding: 5px 10px 5px;
}

@media(max-width: 768px){
	.nav-footer {
		padding: 0;
	}
/* 	.navbar-row {
	    position: fixed;
		bottom: 10px;
		width: calc(100% - 4px);
		height: 90px;
		right: 14px;
	}
	.main-content {
		padding-top: 0;
		padding-bottom: 50px;
	}	 */	
	.swiper-image {
		width: calc(100%);
	}
}

.status-box {
	width: calc(100% - 20px);
    margin: 0 10px;
    border-radius: 10px;
}

  .custom-style {
    --jb-date-input-border-radius:0;
    --jb-date-input-border-color: #dde0e5;
    --jb-date-input-border-color-focus:#000;
    --jb-date-input-bgcolor:#fff;
    --jb-date-input-message-box-color:blue;
    --jb-date-input-border-bottom-width:1px;
    --jb-date-input-label-font-size	:1.3em;
    --jb-date-input-value-color:#434343;
    --jb-date-input-value-font-size:1.3em;
    --jb-date-input-calender-wrapper-bg-color:#fefefe;
    --jb-date-input-calender-wrapper-border-radius:0;
    --jb-calendar-arrow-button-border-radius:0;
	--jb-date-input-value-font-size: 12px;
	--jb-date-input-border-radius: 4px;
	--jb-date-input-calendar-trigger-width: 20px;
	--jb-date-input-calendar-trigger-height: 20px;
  }
  
  .status-box label {
	margin: 5px 3px;
    font-size: 11px;
    padding: 5px 3px;
  }

.prices-box:last-child {
	border-bottom: 0px !important;
	margin-bottom: 0 !important;
	padding-bottom: 0 !important;
}
  
  
  
  
  	.swiper-slide .ag-shop-card_box {
		box-shadow: none;
		border: 1px solid #1b1b1b20;
	}
  
@media(max-width: 768px) {
	
	nav button {
		margin: 0 !important;
	}
	
	.home-swipers {
		/* max-height: 355px; */
	}

		
	.swiper-slide .ag-shop-card_body {
		max-height: 160px;
	}

	.dashboard-fluid {
		padding: 25px 15px !important;
	}
	
	.main-navbar {
	margin: 15px 0px !important;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(0px);
    background: transparent !important;
    box-shadow: none !important;
}

.position-sticky {
	position: relative !important;
}
  
  
.home-swipers  .swiper-slide:nth-child(n) {
  width: 75% !important;
}
.home-swipers  .swiper-slide:nth-child(1) {
  width: 75% !important;
}
	


}

.prices-box {
	margin: 0 !important;
}
  
 .title-list a {
	 float: right;
    font-size: 11px;
    border: 1px solid;
    padding: 5px 10px 3px;
    border-radius: 100px;
    line-height: 13px;
    color: #d1ab66 ;
    margin: 2px -20px;
 }
 
 .rtl  .title-list a {
	 float: left;

 }
 .title-list {
font-size: 18px;
    margin: 20px 0 5px;
    position: relative;
    padding: 0 20px;
    display: block;
	color: #fff;    text-transform: capitalize;
 }
 
 .nav-link, .docs-info h6  {
	 text-transform: capitalize;
 }
 
  .title-list:after {
    content: "";
    position: absolute;
    width: 5px;
    height: 100%;
    top: 0;
    background: #d1ab66 ;
  }
 
 .theme-ltr   .title-list:after {
    left: 0;

 }
 .theme-rtl   .title-list:after {
    right: 0;

 }
 
 
 .mobile-toolbar {
	position: fixed;
    bottom: -1px;
    width: 100%;
    height: 65px;
    background: #1e293b;
    left: 0;
    border-top: 1px solid #1b1b1b10;
	z-index: 10;
 }
 
 .mobile-toolbar-body {
display: flex;
    width: 100%;
    height: 100%;
 }
 
 
 .mobile-toolbar-item.home {
     background-image: url("./assets/icons/HomeSilver256.png");	 
 }
 
 .mobile-toolbar-item.home.active {
    background-image: url("./assets/icons/HomeBlue256.png");	 
 }
 

 
 .mobile-toolbar-item.products {
     background-image: url("./assets/icons/bicycleSilver256.png");	 
	 background-size: auto 45px;
 }
 
 .mobile-toolbar-item.products.active {
     background-image: url("./assets/icons/bicycleBlue256.png");	 
 }
 
 
 .mobile-toolbar-item.orders {
     background-image: url("./assets/icons/CalenderSilver256.png");	 
 }
 
 .mobile-toolbar-item.orders.active {
     background-image: url("./assets/icons/CalenderBlue256.png");	 
 }
 
 
 
 .mobile-toolbar-item.profile {
     background-image: url("./assets/icons/UserSilver256.png");	 
 }
 
 .mobile-toolbar-item.profile.active {
     background-image: url("./assets/icons/UserBlue256.png");	 
 }


 
 
 
 
 
 
 
 .mobile-toolbar-item {
	text-align: center;
    width: 25%;
    height: 100%;
    background-size: auto 25px;
    background-repeat: no-repeat;
    background-position: center;
	}
 
 .mobile-toolbar-item a {
width: 100%;
    display: block;
    height: 100%;
 }
 
 
.mb-5px {
	margin-bottom: 5px;
}
 
.text-capitalize {
	text-transform: capitalize;
}
 
 .products-list {
	 padding: 0 5px;
 }
 
@media(min-width: 768px){
	.in-mobile, .mobile-toolbar {
		display: none !important;
	}
	
}
@media(max-width: 768px){
	.main-content {
		padding: 55px 0 0px;
	}
	.col-prodocut-in-mobile {
		background: #fff;
		border-radius: 25px;
		margin-top: 20px;
		/* margin-bottom: -90px; */
		padding: 10px 20px 20px;
		border: 1px solid #1b1b1b10;
	}
	.in-desktop {
		display: none !important;
	}
	 .product-card .prices-box p {
		 font-size: 15px;
	 }
	 .product-card .prices-box .btn {
		 
		 font-size: 15px;
    padding: 10px 20px;
	 }
	 .product-card {
		 background: transparent !important;
		 box-shadow: none !important;
	 }
	 
	.col-product-in-mobile {
		padding: 0;
	}

 
 #root {
	 /* padding: 10px */
 }
 
 }
 
 
 .all-products {
	 padding: 0 5px;
 }
 
 body, html {
	 background: #f9fafb;
 }
 
 
 .ag-shop-card_box {
	 box-shadow: none;
	 border: 1px solid #1b1b1b10;
 }
 
 .search-box {
	border-radius: 100px;
    background: #fff;
    margin: 10px 0;
    width: calc(100% - 0px);
    border: 1px solid #1b1b1b20;
    height: 50px;
    margin-bottom: 15px;
 }
 
 .search-input {
	     height: 100%;
    display: flex;
    align-items: center;
 }
 
 .search-input i {
    font-size: 20px;
    margin: 10px;
 }
 
 
 .search-input input:placeholder {
    font-size: 14px;

 }
 .search-input input {
    border: 0;
    width: calc(100% - 20px);
    padding: 10px;
    height: 100%;
    font-size: 14px;
    outline: none;
	border-radius: 100px;
 }
 
 
 .navbar-brand {
	letter-spacing: 4px;
    text-transform: uppercase;
 }
 
 
.mb-10px {
	margin-bottom: 10px;
}

.acceptPolicy {
	margin: 0;
    font-size: 11px;
    padding: 0 5px;
	display: block;
}
 
 
 .splash-screen {
	 width: 100vw;
    height: 100vh;
    left: 0;
    position: fixed;
    top: 0;
    z-index: 10000;
    background: #fff   ;
 }
 
.splash-screen .shoar {
    position: absolute;
    bottom: 10px;
    width: 100%;
    text-align: center;
    color: #000;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
}
.theme-rtl .splash-screen .shoar {
    font-family: "IRANSans"

}

.splash-screen .logo {
    width: 250px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    margin-top: -20px;
	/* filter: invert(1); */
}

.splash-screen-swiper {
	padding: 10px;
}
 
 
.splash-screen-swiper h3 {
	font-size: 20px;
    font-weight: bold;
    text-align: center !important;
    margin: 5px 0;
	color: #fff;
}
.splash-screen-swiper img {
	width: 100%;
    height: calc(100vw - 135px);
    max-height: 300px;
    object-fit: contain;
	margin: 20px 0;
}
 
.splash-screen-swiper a {
	font-size: 14px;
    margin: auto;
    border: 1px solid;
    padding: 10px 10px;
    width: 100%;
    max-width: 260px;
    display: block;
    margin-bottom: 50px;
    color: #1b1b1b;
    border-radius: 100px;
    text-align: center;
    padding-top: 10px;
    outline: none;
    color: #fff;
}

.splash-screen-swiper a.fill {
	background: #1e293b;
    color: #fff;
}

 
.splash-screen-swiper p {
	margin: 0;
    color: #1b1b1b80;
    font-size: 15px;
    text-align: center !important;
    margin-top: 5px;
	color: #fff;
	min-height: 70px;
}
.w-fully {
	width: 100% !important;
}
 
.overflow-hidden {
	overflow: hidden;
}
 

 
.auth-pages main {
	background: #fff    !important;
}

.container-auth-login {
}
 
 
.container-auth-login .card {
	background: transparent !important;
	backdrop-filter: none !important;
}


.container-auth-login input{
	margin-top: 5px;
}
 
.container-auth-login *:not(input) {
	color: #000 !important;
}

.container-auth-login .card-header img {
	width: 250px;
	/* filter: invert(1); */
}
 

.container-auth-login input, .container-auth-login button {
	border-radius: 100px;
}
 
 
 
a:hover {
	color: #000;
}
 
 
 
.container-auth-login h3 {
	font-size: 25px;
    text-transform: capitalize; 
}

 
.container-auth-login p {
    font-size: 15px;
    font-weight: 200;
}

.container-auth-login label {
    text-transform: capitalize; 
}

.btn-inner--text {
    text-transform: capitalize; 
}

.container-auth-login form {
	margin-top: 15px;
}

.container-auth-login form button {
	text-transform: uppercase;
    font-size: 12px;
}

.theme-ltr .container-auth-login form button {
    letter-spacing: 2px;

}










#submit-form {
	background: #d2ac67 !important;
    border-color: #d2ac67 !important;
    color: #000 !important;
    margin-top: 25px !important;
}


aside.sidenav  {
	background: #fff !important;
}


.g-sidenav-show.bg-gray-100, body, html {
    background-color: #1e293b !important;
}


.navbar-vertical .navbar-nav .nav-link {
	color: #000;
}


.navbar-vertical.bg-slate-900 .navbar-nav>.nav-item>.nav-link .icon svg .color-foreground {
	fill: #d1ab66  !important;
}


.navbar-vertical.bg-slate-900 .navbar-nav>.nav-item>.nav-link .icon svg .color-background {
    fill: #0d5c46 !important;
}

.navbar-nav i, .text-gold {
	color: #d1ab66 !important;

}


@media(max-width: 768px){
	.navbar .navbar-brand {
		color: #fff !important;
		font-size: 20px;
		letter-spacing: 8px;
	}
	.aside-toggle i {
		color: #fff !important;
	}
	
	.navbar-toggler .navbar-toggler-icon .navbar-toggler-bar {
		background: #fff !important;
		height: 2px;
	}
}



.dear-profile {
	color: #fff;
}


input,
select,
textarea,
input:focus,
select:focus,
textarea:focus,
input:hover,
select:hover,
textarea:hover,
button:focus {
    outline: none !important;
	outline-width: 0;

}



.langs {
	margin: 0px 0px 20px;
}



.langs .lang.active {
    background: #1e293b;	
	color: #fff;
}

.langs .lang {
	text-align: center;
    padding: 10px;
    align-items: center;
    display: flex;
    justify-content: center;
    border-radius: 10px;
	cursor: pointer;
	color: #000;
	font-size: 14px;
	    font-weight: 400;
}

.langs .lang img {
	width: 30px;
}

.filter-categories {
	white-space: nowrap;
    overflow: auto;
	margin-bottom: 15px;
}

.filter-category {
	display: inline-block;
    margin: 0 5px;
    padding: 7px 25px;
    border: 2px solid #d1ab66;
    color: #d1ab66;
    border-radius: 100px;
    min-width: 100px;
    text-align: center;
	cursor: pointer;
}

.filter-category.active {
    color: #000;
    background: #d1ab66;
}

.filter-category p {
    margin: 0;
    font-size: 13px;
    font-weight: bold;
}


.order-box .title {
    font-weight: 500;
    font-size: 17px;
	
}

.order-box label {
	color: #fff;
}

.order-box .label {
	color: #fff;
}

.order-box .radio-txt {
    margin: 0;
    position: relative;
    top: -5px;
    font-size: 13px;
    font-weight: 500;
}

.order-box .radio {
	accent-color: #d1ab66;
    width: 17px;
    height: 17px;
    margin: 0 10px;
}

.order-box {
	position: fixed;
   /*  top: 50%;
    left: calc(50% + 2px);
    transform: translate(-50%, -50%); */
    z-index: 100;
    background: #1e293b;
    padding: 15px 20px;	
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	z-index: 1000000000000000;
	color: #d1ab66;
}

@media(min-width: 768px){
	.order-box {
		width: 500px;
		height: 625px;
		border-radius: 10px;
		border: 3px solid;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		box-shadow: 1px 1px 9px 11px #ffffff30;
	}
}

.order-box-div {
	margin-bottom: 15px;
}

.text-center {
	text-align: center
}

.order-box select {
	width: calc(100% - 10px);
    background: #fff;
    color: #000;
    padding: 10px;
    border-radius: 5px;
    border: 0;
    font-size: 13px;
    margin: 5px 0;
}

.order-box textarea {
	width: calc(100% - 10px);
    background: #fff;
    color: #000;
    padding: 10px;
    border-radius: 5px;
    border: 0;
    font-size: 13px;
    margin: 5px 0;
}


.flex {
	display: flex;
}

.w-half {
	width: 50%;
}



.order-box .close {
	display: block;
    color: #fff;
    text-align: center;
    border: 2px dashed;
    margin: 5px;
    border-radius: 100px;
    padding: 7px;
    font-size: 14px;
    font-weight: bold;
}


.order-box .send {
	display: block;
    color: #d1ab66;
    text-align: center;
    border: 2px dashed;
    margin: 5px;
    border-radius: 100px;
    padding: 7px;
    font-size: 14px;
    font-weight: bold;
}


.details-text {
	font-size: 11px;
    margin: 0;
}


.rent_period_box {
    padding: 0 10px;
    color: #fff;
    font-size: 11px;
    font-weight: 300;
    margin-top: 10px;
}


.rent_period_box .key {
	font-weight: bold;
}


.rent_period_box .value {
	
}

.modal-page {
z-index: 100;
    margin: auto;
    max-width: 330px;
    height: 95%;
    max-height: 620px;
    width: 95%;
    background: #fff;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid #1b1b1b20;
    border-radius: 10px;
    padding: 15px;
}



.modal-page .shoar {
    margin: 0;
    font-size: 14px;
    line-height: 20px;
    height: calc(100% - 60px);
    overflow: auto;
    padding: 10px;
    margin-bottom: 20px;
}


.modal-page a {
    display: block;
    width: 100%;
    border-radius: 100px;
    text-align: center;
    padding: 10px;
    font-weight: 600;
    background: #d2ac67 !important;
    border-color: #d2ac67 !important;
    color: #000 !important;
    letter-spacing: 2px;
    text-transform: uppercase;
    font-size: 12px;
}






.react-datepicker-wrapper {
	width: 100%;
}

.react-datepicker-wrapper input {
	width: calc(100% - 0px);
    background: #fff;
    color: #000;
    padding: 10px;
    border-radius: 5px;
    border: 0;
    font-size: 13px;
    margin: 5px 0;
	padding-top: 12px;
}


.in-product-images {
	white-space: nowrap;
    overflow: auto;
    margin-bottom: 15px;
}

.in-product-images p {
    display: inline-block;
    position: relative;
    margin: 0 10px 10px;
}

.in-product-images p img {
    width: 200px;
    height: 200px;
    border-radius: 10px;
    object-fit: contain;
    border: 1px solid;
}

.change-money {
	margin: 0 15px;
    position: relative;
    top: 2px;
	cursor:pointer;
}

.top-0 {
	top: 0 !important
}

.in-product-images p span {
	position: absolute;
    z-index: 1;
    text-shadow: 0 0 black;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 3px;
    padding: 5px 10px;
    border-radius: 10px;
    font-size: 12px;
    font-weight: bold;
    left: 10px;
    top: 10px;
    cursor: pointer;
    background: #7f0000;
}

.type-file {
	width: 200px;
    display: block;
    margin-top: 8px;
}

.about-sections {
	margin-top: 20px;
    padding: 5px 20px;
}

.about-sections * {
	color: #fff;
    font-size: 14px;
    text-align: justify;

}

[type="tel"]{
	border: 0
}

.PhoneInput {
	border: 1px solid #dde0e5;
	border-radius: 0.375rem;
    border-top-right-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
    border-left-width: 1px;
    box-shadow: 0 1px 2px 0 rgba(12, 26, 36, 0.06);
	padding: 10px;
}


.badge-count {
	color: #fff;
    margin: 10px;
    background: red;
    padding: 3px 8px;
    font-size: 10px;
    border-radius: 10px;
    position: relative;
    top: -1px;
}









