
@font-face{
    font-family: "ArabicTwo";
    src: url("arabic_fonts/GE_SS_Two_Bold.otf");
 
    font-weight:normal;
    font-style:normal;
    font-display:swap;
}



[theme-lang="ar"] *:not(i) {
    font-family: "ArabicTwo", cursive  !important;
}
 

* {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
