@font-face {
	font-family: IRANSans;
	font-style: normal;
	font-weight: normal;
	src: url('persian_fonts/eot/IRANSansWeb(FaNum).eot?#iefix') format('embedded-opentype'),  /* IE6-8 */
		 url('persian_fonts/woff2/IRANSansWeb(FaNum).woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
		 url('persian_fonts/ttf/IRANSansWeb(FaNum).ttf') format('truetype');
}

@font-face {
	font-family: IRANSans;
	font-style: normal;
	font-weight: bold;
	src: url('persian_fonts/eot/IRANSansWeb_Bold.eot');
	src: url('persian_fonts/eot/IRANSansWeb_Bold.eot?#iefix') format('embedded-opentype'),  /* IE6-8 */
		 url('persian_fonts/woff2/IRANSansWeb_Bold.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
		 url('persian_fonts/woff/IRANSansWeb_Bold.woff') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
		 url('persian_fonts/ttf/IRANSansWeb_Bold.ttf') format('truetype');

	src: url('persian_fonts/eot/IRANSansWeb(FaNum)_Bold.eot');
	src: url('persian_fonts/eot/IRANSansWeb(FaNum)_Bold.eot?#iefix') format('embedded-opentype'),  /* IE6-8 */
		 url('persian_fonts/woff2/IRANSansWeb(FaNum)_Bold.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
		 url('persian_fonts/woff/IRANSansWeb(FaNum)_Bold.woff') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
		 url('persian_fonts/ttf/IRANSansWeb(FaNum)_Bold.ttf') format('truetype');
}
@font-face {
	font-family: IRANSans;
	font-style: normal;
	font-weight: 500;
	src: url('persian_fonts/eot/IRANSansWeb_Medium.eot');
	src: url('persian_fonts/eot/IRANSansWeb_Medium.eot?#iefix') format('embedded-opentype'),  /* IE6-8 */
		 url('persian_fonts/woff2/IRANSansWeb_Medium.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
		 url('persian_fonts/woff/IRANSansWeb_Medium.woff') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
		 url('persian_fonts/ttf/IRANSansWeb_Medium.ttf') format('truetype');

	src: url('persian_fonts/eot/IRANSansWeb(FaNum)_Medium.eot');
	src: url('persian_fonts/eot/IRANSansWeb(FaNum)_Medium.eot?#iefix') format('embedded-opentype'),  /* IE6-8 */
		 url('persian_fonts/woff2/IRANSansWeb(FaNum)_Medium.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
		 url('persian_fonts/woff/IRANSansWeb(FaNum)_Medium.woff') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
		 url('persian_fonts/ttf/IRANSansWeb(FaNum)_Medium.ttf') format('truetype');

}
@font-face {
	font-family: IRANSans;
	font-style: normal;
	font-weight: 300;
	src: url('persian_fonts/eot/IRANSansWeb_Light.eot');
	src: url('persian_fonts/eot/IRANSansWeb_Light.eot?#iefix') format('embedded-opentype'),  /* IE6-8 */
		 url('persian_fonts/woff2/IRANSansWeb_Light.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
		 url('persian_fonts/woff/IRANSansWeb_Light.woff') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
		 url('persian_fonts/ttf/IRANSansWeb_Light.ttf') format('truetype');
	
	src: url('persian_fonts/eot/IRANSansWeb(FaNum)_Light.eot');
	src: url('persian_fonts/eot/IRANSansWeb(FaNum)_Light.eot?#iefix') format('embedded-opentype'),  /* IE6-8 */
		 url('persian_fonts/woff2/IRANSansWeb(FaNum)_Light.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
		 url('persian_fonts/woff/IRANSansWeb(FaNum)_Light.woff') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
		 url('persian_fonts/ttf/IRANSansWeb(FaNum)_Light.ttf') format('truetype');


}
@font-face {
	font-family: IRANSans;
	font-style: normal;
	font-weight: 200;
	src: url('persian_fonts/eot/IRANSansWeb_UltraLight.eot');
	src: url('persian_fonts/eot/IRANSansWeb_UltraLight.eot?#iefix') format('embedded-opentype'),  /* IE6-8 */
		 url('persian_fonts/woff2/IRANSansWeb_UltraLight.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
		 url('persian_fonts/woff/IRANSansWeb_UltraLight.woff') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
		 url('persian_fonts/ttf/IRANSansWeb_UltraLight.ttf') format('truetype');
	src: url('persian_fonts/eot/IRANSansWeb(FaNum)_UltraLight.eot');
	src: url('persian_fonts/eot/IRANSansWeb(FaNum)_UltraLight.eot?#iefix') format('embedded-opentype'),  /* IE6-8 */
		 url('persian_fonts/woff2/IRANSansWeb(FaNum)_UltraLight.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
		 url('persian_fonts/woff/IRANSansWeb(FaNum)_UltraLight.woff') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
		 url('persian_fonts/ttf/IRANSansWeb(FaNum)_UltraLight.ttf') format('truetype');


}
@font-face {
	font-family: IRANSans;
	font-style: normal;
	font-weight: normal;
	src: url('persian_fonts/eot/IRANSansWeb.eot');
	src: url('persian_fonts/eot/IRANSansWeb.eot?#iefix') format('embedded-opentype'),  /* IE6-8 */
		 url('persian_fonts/woff2/IRANSansWeb.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
		 url('persian_fonts/woff/IRANSansWeb.woff') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
		 url('persian_fonts/ttf/IRANSansWeb.ttf') format('truetype');
	src: url('persian_fonts/eot/IRANSansWeb(FaNum).eot');
	src: url('persian_fonts/eot/IRANSansWeb(FaNum).eot?#iefix') format('embedded-opentype'),  /* IE6-8 */
		 url('persian_fonts/woff2/IRANSansWeb(FaNum).woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
		 url('persian_fonts/woff/IRANSansWeb(FaNum).woff') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
		 url('persian_fonts/ttf/IRANSansWeb(FaNum).ttf') format('truetype');

}
@font-face {
	font-family: Lalezar;
	font-style: normal;
	font-weight: normal;
	src: url('persian_fonts/Lalezar-Regular.ttf') format('truetype');
}


[theme-lang="fa"] *:not(i) {
    font-family: "IRANSans", cursive  !important;
}
 
[theme-lang="en"] *:not(i) {
    font-family: "Poppins" !important;
}

* {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}



